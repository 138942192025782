.ken-productmoodboard {
  &__tab__product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px 10px 40px;
    background-color: $ken-color-white;

    &--missing {
      background-color: $ken-color-white;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 20px 10px 40px;
      width: calc(50% - 5px);

      @include bp('ken-breakpoint-m') {
      flex-direction: row;
      height: calc(50% - 10px);
      width: 100%;
      }

      p {
        padding-top: 25px;
      }
    }

    .ken-productmoodboard__tab__side-column & {
      width: calc(50% - 5px);

      @include bp('ken-breakpoint-m') {
        width: unset;
        height: calc(50% - 10px);
      }

      .--labelPrice {
        width: 100%;
        @include bp('ken-breakpoint-m') {
          width: auto;
        }
      }
    }
    
    .ken-productmoodboard__tab__side-column--full-tile-product & {
      width: 100%;

      @include bp('ken-breakpoint-m') {
        height: 100%;
      }
    }

    &__image {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 100%;
      margin-bottom: 25px;

      // TODO: Remove height, width and border
      width: 118px;
      height: 118px;

      @include bp('ken-breakpoint-m') {
        // TODO: Remove height and width
        width: 148px;
        height: 148px;
      }

      .ken-productmoodboard__tab__main-product & {
        // TODO: Remove height and width
        width: 258px;
        height: 258px;

        @include bp('ken-breakpoint-m') {
          // TODO: Remove height and width
          width: 458px;
          height: 458px;
        }
      }

      .ken-productmoodboard__tab__side-column--full-tile-product & {
        // TODO: Remove height and width
        width: 258px;
        height: 258px;

        @include bp('ken-breakpoint-m') {
          // TODO: Remove height and width
          width: 148px;
          height: 458px;
        }
      }

      picture,
      picture * {
        width: 100%;
        height: 100%;
      }
    }

    &__textgroup {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__name {
      font-family: var(--fontNameBold);
      font-size: 14px;
      font-weight: bold;
      line-height: 1.29;
      letter-spacing: 0.2px;
      text-align: center;
      color: $ken-color-black;
    }

    &__prices {
      .--list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        list-style: none;
        gap: 4px;
        font-family: var(--fontNameRegular);
        font-size: 14px;
        color: $ken-color-grey-xxl;

        .--mainPrice {
          width: 100%;
          padding-bottom: 5px;
          order: 0;
          text-align: center;
        }

        .--info {
          display: flex;
          gap: 4px;
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;
        }

        .--strikePrice,
        .--percentageDiscount,
        .--labelPrice {
          font-size: 12px;
          line-height: 10px;
        }

        .--strikePrice {
          text-decoration: line-through;
          order: 2;
        }

        .--percentageDiscount {
          color: $ken-color-rhubarb;
          font-family: var(--fontNameSemibold);
          order: 3;
        }

        .--percentageDiscount::before {
          content: '(';
        }

        .--percentageDiscount::after {
          content: ')';
        }

        .--labelPrice {
          order: 1;
          text-align: center;
        }
      }

    }
  }

  &__tab__main-product--missing{
    background-color: $ken-color-white;
    width: 100%;
    height: 100%;

    p {
      text-align: center;
      padding-top: 25px;
    }
  }

  &__tab__four-missing {
    background-color: $ken-color-white;
    width: calc(50% -5px);

    &--full {
      width: 100%;
      background-color: $ken-color-white;

      p {
        padding-top: 25px;
        text-align: center;
      }
    }

    @include bp('ken-breakpoint-m') {
      width: 100%;
      height: calc(50% - 10px);

      &--full {
        height: 100%;
      }
    }

    p {
      padding-top: 25px;
      text-align: center;
    }
  }
}
